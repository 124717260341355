import React from "react";
import styles from "./CustomTooltip.module.css"; // Ensure this file exists

// Helper: Remove underscores and capitalize each word.
const formatKey = (key) => {
  if (!key) return "";
  return key
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// Helper: Convert seconds into "X mins Y secs" format.
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

const CustomTooltip = ({
  active,
  payload,
  label,
  valueLabel = "Users",
  highlightKey,
  selectedTab,
  dynamic = false, // true for content data, false for category data
}) => {
  if (!active || !payload || payload.length === 0) return null;

  return (
    <div className={styles.tooltipContainer}>
      {label && <p className={styles.tooltipLabel}>{label}</p>}
      {payload.map((entry, index) => {
        // Format the key label (e.g. "total_duration" → "Total Duration")
        const keyLabel =
          entry.name && entry.name !== "value" ? formatKey(entry.name) : valueLabel;

        let rawValue;
        let isDuration = false;

        // If selectedTab equals "value", simply return the raw value.
        if (selectedTab === "views") {
          rawValue = entry.value;
          isDuration = false;
        } else if (dynamic && entry.name && entry.name.toLowerCase().includes("duration")) {
          // For content data, force duration formatting.
          isDuration = true;
          const secKey = `${entry.name}_sec`;
          if (entry.payload && Object.prototype.hasOwnProperty.call(entry.payload, secKey)) {
            rawValue = entry.payload[secKey];
          } else {
            rawValue = entry.value;
          }
        } else {
          // For category data (or non-duration fields), use the existing logic.
          const secKey = `${entry.name}_sec`;
          if (entry.payload && Object.prototype.hasOwnProperty.call(entry.payload, secKey)) {
            rawValue = entry.payload[secKey];
            isDuration = true;
          } else {
            rawValue =
              entry.payload && entry.payload.actual !== undefined
                ? entry.payload.actual
                : entry.value;
          }
        }

        const numericValue = Number(rawValue);
        const displayValue =
          !isNaN(numericValue) && isDuration ? formatTime(numericValue) : rawValue;
        const isHighlighted = highlightKey && entry.name === highlightKey;

        return (
          <p
            key={index}
            className={isHighlighted ? styles.highlightedValue : styles.tooltipValue}
          >
            {keyLabel}: <span>{displayValue}</span>
          </p>
        );
      })}
    </div>
  );
};

export default CustomTooltip;
