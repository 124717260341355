import React from "react";
import styles from "./DashboardHeader.module.css";

const DashboardHeader = ({ dashboardMessage }) => {
  const { headline, content } = dashboardMessage;
  // Remove HTML tags from a string
  const stripHtml = (html) => {
    if (!html) return "";
    return html.replace(/<[^>]*>/g, "");
  };

  const plainContent = stripHtml(content);

  return (
    <div className={styles.container}>
      <div>
        <h3 className={styles.title}>{headline}</h3>
        <p className={styles.paragraph}>
          {plainContent}
        </p>
      </div>
    </div>
  );
};

export default DashboardHeader;