import mainApi from "./mainApi";

export function portalApi() {
  return {
    getPortalCustomerAccountBasicInfo: async function() {
      return mainApi.get(`/customer/account/basic`);
    },
    getPortalCustomerAccountLocationInfo: async function() {
      return mainApi.get(`/customer/account/location`);
    },
    getPortalCustomerAccountContractInfo: async function() {
      return mainApi.get(`/customer/account/contract`);
    },
    getPortalCustomerAccountBillingInfo: async function() {
      return mainApi.get(`/customer/account/billing`);
    },
    getPortalCustomerAccountTreoRepInfo: async function() {
      return mainApi.get(`/customer/account/representative`);
    },
    getPortalCustomerOrganizationId: async function() {
      return mainApi.get(`/customer/organization`);
    },
    getPortalOrganizationDocumentCategories: async function(organizationId) {
      if (!organizationId) {
        throw new Error("Organization ID is required");
      }
      return mainApi.get(
        `/organizations/get/document/categories/${organizationId}`
      );
    },
    getPortalOrganizationDocumentsByCategory: async function(
      organizationId,
      categoryId,
      limit = 25,
      page = 1
    ) {
      if (!organizationId || !categoryId) {
        throw new Error("Organization ID and Category ID are required");
      }
      return mainApi.get(
        `/organizations/documents/byCategory/${organizationId}/${categoryId}`,
        {
          params: { limit, page },
        }
      );
    },

    postSubmitSupportRequest: async function(name, email, topic, description) {
      if (!name || !email || !topic || !description) {
        throw new Error(
          "All fields (name, email, topic, description) are required"
        );
      }
      if (!["billing", "technical"].includes(topic)) {
        throw new Error(
          'Invalid topic. Topic must be "billing" or "technical".'
        );
      }
      if (description.length > 1000) {
        throw new Error(
          "Description exceeds the maximum length of 1000 characters."
        );
      }
      return mainApi.post(`/customer/support/requests`, {
        name,
        email,
        topic,
        description,
      });
    },

    getPortalCustomerActiveUsers: async function(startDate, endDate) {
      if (!startDate || !endDate) {
        throw new Error("Both start_date and end_date are required.");
      }

      return mainApi.get(`/customer/active/users`, {
        params: { start_date: startDate, end_date: endDate },
      });
    },

    getPortalCustomerOnboardingStats: async function() {
      return mainApi.get(`/customer/onboarding`);
    },

    getPortalCustomerGenderStats: async function() {
      return mainApi.get(`/customer/gender`);
    },
    getPortalCustomerAgeStats: async function() {
      return mainApi.get(`/customer/age`);
    },
    getPortalCustomerAdoptionStats: async function() {
      return mainApi.get(`/customer/adoption`);
    },
    /**
     * Admin Site: Change the order of documents in a category for an organization.
     */
    postSortOrganizationDocuments: async function(
      organizationId,
      categoryId,
      documentIds
    ) {
      if (!organizationId || !categoryId) {
        throw new Error("Organization ID and Category ID are required.");
      }
      if (!Array.isArray(documentIds) || documentIds.length === 0) {
        throw new Error("document_ids must be a non-empty array.");
      }
      return mainApi.post(
        `/organizations/sort/documents/${organizationId}/${categoryId}`,
        {
          document_ids: documentIds,
        }
      );
    },
    postUploadOrganizationDocument: async function(
      organizationId,
      label,
      categoryId,
      pdfFile
    ) {
      if (!organizationId) {
        throw new Error("Organization ID is required.");
      }
      if (!label || !categoryId || !pdfFile) {
        throw new Error("Label, category ID, and a PDF file are required.");
      }

      const formData = new FormData();
      formData.append("label", label);
      formData.append("category_id", categoryId);
      formData.append("pdf", pdfFile);

      return mainApi.post(
        `/organizations/upload/document/${organizationId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
    },
    postDeleteOrganizationDocumentCategory: async function(
      organizationId,
      categoryId
    ) {
      if (!organizationId || !categoryId) {
        throw new Error("Organization ID and Category ID are required.");
      }
      return mainApi.post(
        `/organizations/delete/document/category/${organizationId}/${categoryId}`
      );
    },
    postDeleteOrganizationDocument: async function(organizationId, documentId) {
      if (!organizationId || !documentId) {
        throw new Error("Organization ID and Document ID are required.");
      }
      return mainApi.post(
        `/organizations/delete/document/${organizationId}/${documentId}`
      );
    },

    /**
     * Admin Site: Create a new document category for an organization.
     */
    postCreateOrganizationDocumentCategory: async function(
      organizationId,
      label
    ) {
      if (!organizationId) {
        throw new Error("Organization ID is required.");
      }
      if (!label || typeof label !== "string") {
        throw new Error("Label is required and must be a string.");
      }

      return mainApi.post(
        `/organizations/create/document/category/${organizationId}`,
        {
          label,
        }
      );
    },
    postCreateCustomerPortalUser: async function(userbody) {
      return mainApi.post(`/customer/new-customer`, userbody);
    },
    getPortalCustomerUsers: async function(organizationId) {
      if (!organizationId) {
        throw new Error("Organization ID is required.");
      }
      return mainApi.get(`/customer/user/list/${organizationId}`);
    },
    removeCustomerUser: async function(email) {
        if (!email) {
          throw new Error("Customer email is required.");
        }
        return mainApi.put(`/user/remove-user/${email}`);
      },
      getCustomerDashboardMessage: async function(organizationId) {
        let url = `/customer/dashboard/message`;
        // If organizationId is provided, add it as a query parameter
        if (organizationId) {
          url += `?organization_id=${organizationId}`;
        }
        return mainApi.get(url);
      },
      
      postPortalDashboardMessage: async function(organizationId, headline, content) {
        if (!organizationId) {
          throw new Error("Organization ID is required.");
        }

        return mainApi.post(
          `/organizations/portal/message/${organizationId}`,
          {
            headline,
            content,
          }
        );
      },

      getPortalCustomerActiveUsersTrends: async function(startDate, endDate) {
        if (!startDate || !endDate) {
          throw new Error("Both start_date and end_date are required.");
        }
        return mainApi.get(`/customer/active/users/trends`, {
          params: { start_date: startDate, end_date: endDate },
        });
      },

      getPortalCustomerUserInfo: async function() {
        return mainApi.get(`/customer/user/info`);
      },

      getPortalCustomerContentTotalViews: async function(startDate, endDate) {
        if (!startDate || !endDate) {
          throw new Error("Both start_date and end_date are required.");
        }
        return mainApi.get(`/customer/content/total/views`, {
          params: { start_date: startDate, end_date: endDate },
        });
      },

      getPortalCustomerContentTotalWatchtime: async function(startDate, endDate) {
        if (!startDate || !endDate) {
          throw new Error("Both start_date and end_date are required.");
        }
        return mainApi.get(`/customer/content/total/watchtime`, {
          params: { start_date: startDate, end_date: endDate },
        });
      },

      getPortalCustomerContentAverageWatchtime: async function(startDate, endDate) {
        if (!startDate || !endDate) {
          throw new Error("Both start_date and end_date are required.");
        }
        return mainApi.get(`/customer/content/average/watchtime`, {
          params: { start_date: startDate, end_date: endDate },
        });
      },
      
      getPortalCustomerCategoryTotalViews: async function(startDate, endDate) {
        if (!startDate || !endDate) {
          throw new Error("Both start_date and end_date are required.");
        }
        return mainApi.get(`/customer/category/total/views`, {
          params: { start_date: startDate, end_date: endDate },
        });
      },

      getPortalCustomerCategoryWatchtime: async function(startDate, endDate) {
        if (!startDate || !endDate) {
          throw new Error("Both start_date and end_date are required.");
        }
        return mainApi.get(`/customer/category/watchtime`, {
          params: { start_date: startDate, end_date: endDate },
        });
      },

      getPortalCustomerCategoryAverageWatchtime: async function(startDate, endDate) {
        if (!startDate || !endDate) {
          throw new Error("Both start_date and end_date are required.");
        }
        return mainApi.get(`/customer/category/average/watchtime`, {
          params: { start_date: startDate, end_date: endDate },
        });
      },
      
      getPortalCustomerBadgeInfo: async function(startDate, organizationId,   page = 1, 
        limit = 5) {
        if (!startDate) {
          throw new Error("start_date required.");
        }
        return mainApi.get(`/customer/current/badges`, {
          params: { start_date: startDate, organization_id: organizationId,   page, 
            limit},
        });

      },

  

      getPortalContentList(query = "", searchParams = {}) {
        let formattedQuery = formatQuery(query);
      
        // Append start_date and end_date first (they are required)
        if (searchParams.start_date) {
          formattedQuery += `start_date=${encodeURIComponent(searchParams.start_date)}&`;
        }
        if (searchParams.end_date) {
          formattedQuery += `end_date=${encodeURIComponent(searchParams.end_date)}&`;
        }
        
        // Append pagination parameters
        if (searchParams.page) {
          formattedQuery += `page=${encodeURIComponent(searchParams.page)}&`;
        }
        if (searchParams.limit) {
          formattedQuery += `limit=${encodeURIComponent(searchParams.limit)}&`;
        }
        
        // Append other optional parameters
        if (searchParams.title) {
          formattedQuery += `title=${encodeURIComponent(searchParams.title)}&`;
        }
        if (searchParams.category) {
          formattedQuery += `category_id=${encodeURIComponent(searchParams.category)}&`;
        }
        if (searchParams.contentType) {
          formattedQuery += `content_type_id=${encodeURIComponent(searchParams.contentType)}&`;
        }
        if (searchParams.sort) {
          formattedQuery += `sort=${encodeURIComponent(searchParams.sort)}&`;
        }
        if (searchParams.organization_id) {
          formattedQuery += `organization_id=${encodeURIComponent(searchParams.organization_id)}&`;
        }
      
        // Remove trailing ampersand, if any
        if (formattedQuery.endsWith("&")) {
          formattedQuery = formattedQuery.slice(0, -1);
        }
      
        return mainApi.post(`/facts/content/list${formattedQuery}`);
      },
      

      getPortalCategories: async function(organizationId) {
        if (!organizationId) {
          throw new Error("Organization ID is required.");
        }
        return mainApi.get(`/customer/portalCategories`, {
          params: { organization_id: organizationId },
        });
      },
  
    }
}
// Example helper to ensure a query string starts with "?"
function formatQuery(query) {
  // If query is provided and starts with '?', return it; otherwise, return '?'.
  return query && query.startsWith('?') ? query : '?';
}




   