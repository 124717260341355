import React, { useState, useEffect } from "react";
import WYSIWYG from "../../../WYSIWYG/WYSIWYG";
import InputField from "../../../InputField/InputField";
import { connect } from "react-redux";
import { portalApiMap } from "../../../../store/actions/portalAction.js";
import styles from "./CustomerPortalUsers.module.css"; // Import styles
import { BoxContainer } from "../../../containers/boxContainer/boxContainer";
import { fetchCustomerDashboardMessage } from "../../../portalDashboard/routes/DashboardPortalRoutes";

const PortalDashboardMessage = (props) => {
  const { portalApiMap, organizationId } = props;

  // State to store headline and content
  const [headline, setHeadline] = useState("");
  const [content, setContent] = useState("");

  // Handler to update headline from the input field
  const handleHeadlineChange = (event) => {
    setHeadline(event.target.value);
  };

  // Fetch the dashboard message on component mount
  useEffect(() => {
    const getDashboardMessage = async () => {
      try {
        // Pass organizationId if available
        const response = await fetchCustomerDashboardMessage(
          portalApiMap,
          organizationId
        );
        // Prepopulate states only if data exists
        if (response) {
          if (response.headline) {
            setHeadline(response.headline);
          }
          if (response.content) {
            setContent(response.content);
          }
        }
      } catch (err) {
        console.error("Error fetching dashboard message:", err);
      }
    };

    getDashboardMessage();
  }, [portalApiMap, organizationId]);

  // Determine editor height: if both headline and content exist, use a larger height.
  const editorHeight = 400;

  // Handler to send the updated dashboard message
  const handlePostDashboardMessage = async () => {
    try {
      const { postPortalDashboardMessage } = await portalApiMap();
      const response = await postPortalDashboardMessage(
        organizationId,
        headline,
        content
      );
      console.log(response);
      alert("Dashboard message updated successfully!");
    } catch (error) {
      alert("Error creating/updating message: " + error.message);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Headline Input Field */}
      <InputField
        title="Headline"
        label="Headline"
        labelStyle={{ color: "black", fontWeight: 400 }}
        inputStyle={{ backgroundColor: "white" }}
        value={headline}
        onChange={handleHeadlineChange}
      />

      {/* WYSIWYG Editor for Content */}
      <WYSIWYG
        value={content}
        onChangeHandler={setContent}
        editorHeight={editorHeight}
        plugins="lists link image code"
        toolbar="undo redo | bold italic | alignleft aligncenter alignright"
      />

      <BoxContainer
        className={styles.customerBtnContainer}
        onClick={handlePostDashboardMessage}
      >
        <span>Save</span>
      </BoxContainer>
    </div>
  );
};

const actions = { portalApiMap };

export default connect(null, actions)(PortalDashboardMessage);
