/**
 * Fetches active users data for the customer portal dashboard
 * Requires: Logged in Customer User and start_date & end_date in datetime format (ISO 8601)
 */
export const fetchPortalCustomerActiveUsers = async (portalApiMap, startDate, endDate) => {
    try {
        const { getPortalCustomerActiveUsers } = await portalApiMap();
        const response = await getPortalCustomerActiveUsers(startDate, endDate);
        return response.data.results; // Return the response for further use
    } catch (err) {
        console.error("Error fetching active users:", err);
        throw err; // Rethrow the error for handling in the caller
    }
};

export const fetchPortalCustomerActiveUsersTrends = async (portalApiMap, startDate, endDate) => {
    try {
        const { getPortalCustomerActiveUsersTrends } = await portalApiMap();
        const response = await getPortalCustomerActiveUsersTrends(startDate, endDate);
        return response.data.results; // Return the trends data for further use
    } catch (err) {
        console.error("Error fetching active users trends:", err);
        throw err; // Rethrow the error for handling in the caller
    }
};

export const fetchPortalCustomerOnboardingStats = async (portalApiMap) => {
    try {
        const { getPortalCustomerOnboardingStats } = await portalApiMap();
        const response = await getPortalCustomerOnboardingStats();
        return response.data.results // Return the response for further use
    } catch (err) {
        console.error("Error fetching onboarding stats:", err);
        throw err; // Rethrow the error for handling in the caller
    }
};


export const fetchPortalCustomerGenderStats = async (portalApiMap) => {
    try {
        const { getPortalCustomerGenderStats } = await portalApiMap();
        const response = await getPortalCustomerGenderStats();
        return response.data.results; // Return the gender statistics for further use
    } catch (err) {
        console.error("Error fetching gender statistics:", err);
        throw err; // Rethrow the error for handling in the caller
    }
};

export const fetchPortalCustomerAgeStats = async (portalApiMap) => {
    try {
        const { getPortalCustomerAgeStats } = await portalApiMap();
        const response = await getPortalCustomerAgeStats();
        return response.data.results;  
    } catch (err) {
        console.error("Error fetching age statistics:", err);
        throw err; // Rethrow the error for handling in the caller
    }
};



export const fetchPortalCustomerAdoptionStats = async (portalApiMap) => {
    try{
        const { getPortalCustomerAdoptionStats} = await portalApiMap();
        const response = await getPortalCustomerAdoptionStats();
        return response.data.results.adoption[0]; // Return the adoption statistics for further use
    } catch (err) {
        console.error("Error fetching adoption statistics:", err);
        throw err; // Rethrow the error for handling in the caller
    }
};


export const fetchCustomerDashboardMessage = async (portalApiMap, organizationId) => {
  try {
    const { getCustomerDashboardMessage } = await portalApiMap();
    const response = await getCustomerDashboardMessage(organizationId);
    return response.data.results; // Return the dashboard message for further use
  } catch (err) {
    console.error("Error fetching dashboard message", err);
    throw err; // Rethrow the error for handling in the caller
  }
};


export const fetchPortalCustomerContentTotalViews = async (portalApiMap, startDate, endDate) => {
    try {
      const { getPortalCustomerContentTotalViews } = await portalApiMap();
      const response = await getPortalCustomerContentTotalViews(startDate, endDate);
      return response.data.result; // Return the content views data for further use
    } catch (err) {
      console.error("Error fetching content total views:", err);
      throw err; // Rethrow the error for handling in the caller
    }
  };


  export const fetchPortalCustomerContentTotalWatchtime = async (portalApiMap, startDate, endDate) => {
    try {
      const { getPortalCustomerContentTotalWatchtime } = await portalApiMap();
      const response = await getPortalCustomerContentTotalWatchtime(startDate, endDate);
      return response.data.result; // Return the content watchtime data for further use
    } catch (err) {
      console.error("Error fetching content total watchtime:", err);
      throw err; // Rethrow the error for handling in the caller
    }
  };
  

  export const fetchPortalCustomerContentAverageWatchtime = async (portalApiMap, startDate, endDate) => {
    try {
      const { getPortalCustomerContentAverageWatchtime } = await portalApiMap();
      const response = await getPortalCustomerContentAverageWatchtime(startDate, endDate);
      return response.data.result; // Return the average watchtime data for further use
    } catch (err) {
      console.error("Error fetching content average watchtime:", err);
      throw err; // Rethrow the error for handling in the caller
    }
  };
  

  export const fetchPortalCustomerCategoryTotalViews = async (portalApiMap, startDate, endDate) => {
    try {
      const { getPortalCustomerCategoryTotalViews } = await portalApiMap();
      const response = await getPortalCustomerCategoryTotalViews(startDate, endDate);
      return response.data.result; // Return the category views data for further use
    } catch (err) {
      console.error("Error fetching category total views:", err);
      throw err; // Rethrow the error for handling in the caller
    }
  };

  export const fetchPortalCustomerCategoryWatchtime = async (portalApiMap, startDate, endDate) => {
  try {
    const { getPortalCustomerCategoryWatchtime } = await portalApiMap();
    const response = await getPortalCustomerCategoryWatchtime(startDate, endDate);
    return response.data.result; // Return the category watchtime data for further use
  } catch (err) {
    console.error("Error fetching category watchtime:", err);
    throw err; // Rethrow the error for handling in the caller
  }
};


export const fetchPortalCustomerCategoryAverageWatchtime = async (portalApiMap, startDate, endDate) => {
    try {
      const { getPortalCustomerCategoryAverageWatchtime } = await portalApiMap();
      const response = await getPortalCustomerCategoryAverageWatchtime(startDate, endDate);
      return response.data.result; // Return the category average watchtime data for further use
    } catch (err) {
      console.error("Error fetching category average watchtime:", err);
      throw err; // Rethrow the error for handling in the caller
    }
  };
  

  
export const fetchPortalCustomerBadgeInfo = async (portalApiMap, startDate, organizationId) => {
  try {
    const {  getPortalCustomerBadgeInfo } = await portalApiMap();
    const response = await  getPortalCustomerBadgeInfo(startDate, organizationId);
    return response; // Return the category average watchtime data for further use
  } catch (err) {
    console.error("Error fetching category average watchtime:", err);
    throw err; // Rethrow the error for handling in the caller
  }
};

export const fetchPortalCustomerBadges = async (startDate, organizationId, page, limit, portalApiMap) => {
  try {
    const {   getPortalCustomerBadgeInfo } = await portalApiMap();
    const response = await getPortalCustomerBadgeInfo(startDate, organizationId, page, limit);
    return response.data.result // Return the response for further use
  } catch (err) {
    console.error("Error fetching account data:", err);
    throw err; // Rethrow the error for handling in the caller
  }
};


// DashboardPortalRoutes.js
export const fetchPortalContentList = async (portalApiMap, params) => {
  try {
    const { getPortalContentList } = await portalApiMap();
    // Ensure that params contains start_date and end_date (in ISO format)
    const response = await getPortalContentList("", params);
    return response.data.result; // The backend returns the final data in the `result` property.
  } catch (err) {
    console.error("Error fetching portal content list:", err);
    throw err; // Rethrow for handling in the caller.
  }
};


export const fetchPortalCategories = async (portalApiMap, organizationId) => {
  try {
    const { getPortalCategories } = await portalApiMap();
    const response = await getPortalCategories(organizationId);
    // Assume the backend returns the categories in response.data.categories.
    return response.data.result;
  } catch (err) {
    console.error("Error fetching portal categories:", err);
    throw err;
  }
};
